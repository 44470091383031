import mutationTypes from './mutation-types'
import { findDuplicates } from '@/api/on-entry'
import {
  getDupeSetStats,
  getOnEntryDuplicatesToReview,
  getOnEntryDataOffenders,
} from '@/api/on-entry'

export default {
  setActiveViewType({ commit }, type) {
    commit(mutationTypes.SET_ACTIVE_VIEW_TYPE, type)
  },
  async searchDuplicates({ commit }, { inputType, inputQuery }) {
    try {
      const duplicates = await findDuplicates(inputType, inputQuery)

      commit(mutationTypes.SET_STATE, { inputType, inputQuery, duplicates })
    } catch (error) {
      console.log('get contacts error: ', error)
    }
  },
  closeDialog({ commit }) {
    commit(mutationTypes.CLOSE_DIALOG)
  },
  openDialog({ commit }, data) {
    commit(mutationTypes.OPEN_DIALOG)
    commit(mutationTypes.SET_DIALOG_CONTENT, data)
  },
  checkForItemConflicts({ commit }, item) {
    commit(mutationTypes.CHECK_FOR_ITEM_CONFLICTS, item)
  },
  async getDupeSetsToReviewStats({ commit, dispatch }) {
    try {
      const response = await getDupeSetStats()

      commit(mutationTypes.SET_DUPLICATES_TO_REVIEW_STATS, response)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      console.error('AP:getDupesToReviewCountError', error)
    }
  },
  async getDuplicatesToReview({ commit }) {
    try {
      const response = await getOnEntryDuplicatesToReview()

      commit(mutationTypes.SET_DUPLICATES_TO_REVIEW, response.items)
    } catch (error) {
      console.log('GET DUPES TO REVIEW ERROR: ', error)
    }
  },
  async getDataOffenders({ commit }) {
    try {
      const onEntryDataOffenders = await getOnEntryDataOffenders()

      commit(mutationTypes.SET_DATA_OFFENDERS, onEntryDataOffenders)
    } catch (error) {
      console.log('GET ON ENTRY DATA OFFENDERS ERROR: ', error)
    }
  },
  setEventType({ commit }, type) {
    commit(mutationTypes.SET_EVENT_TYPE, type)
  },
  onFormSave({ dispatch, getters }, inputQuery) {
    const isAdminAction = getters.isBlockedByAdmin || getters.isWarnByAdmin

    if (isAdminAction) {
      dispatch('openDialog', inputQuery)
    }
  },
}
